import { FlowyProject } from "../components/gizmos";
import usePlatformCB from "./usePlatformCB";
import * as S from "./BaseProject.styles";
import { defaultCoreProject } from "./gizmoConfigurations";

/**
 *  We created this in order to not touch the DefaultProject component.
 */
const BaseProject: React.FC = () => {
  const { platformOperationCB, params } = usePlatformCB();

  return (
    <S.BaseProject>
      <FlowyProject
        config={defaultCoreProject}
        platformCallbacks={{
          platformOperationCB,
        }}
        params={params}
      />
    </S.BaseProject>
  );
};

export default BaseProject;
