import { Gizmo } from "flowy-3-core";

interface IParams {
  gizmo: Gizmo;
}

/**
 * Download batch index from the server
 *
 * @param {Gizmo} gizmo index gizmo
 */
async function downloadBatch({ gizmo }: IParams) {
  const binder = gizmo.getBinder();

  if (binder) {
    const { data } = await binder.dispatchOperation({
      operation: "export.download",
      params: {
        fid: gizmo.getParams().fid,
      },
    });

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    // link.setAttribute("download", "reports.csv");
    link.setAttribute("download", "report.zip");
    document.body.appendChild(link);
    link.click();
  }
}

export default downloadBatch;
