import { Gizmo } from "flowy-3-core";
import { Row } from "antd";
// import { quintanaRooLogoMujerEsVida } from "../../assets/images";

type LoginHeaderProps = {
  gizmo: Gizmo;
};

const LoginHeader = ({ gizmo }: LoginHeaderProps) => {
  return (
    <Row justify={"center"}>
      {/* <img
        src={quintanaRooLogoMujerEsVida}
        style={{
          maxWidth: "100%",
        }}
      /> */}
    </Row>
  );
};

export default LoginHeader;
