import { IFlowyProject, IGizmo } from "flowy-3-core";
// import { LoginCustomGizmo } from "../custom";

const loginForm: IGizmo = {
  fid: "login-form-view-fid",
  type: "view",
  label: "Login Form",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    // {
    //   fid: "g100",
    //   type: "text",
    //   label: "Login Header",
    // },
    {
      fid: "g111",
      type: "custom",
      label: "Login Custom Gizmo",
      ops: {
        custom: {
          customParams: {},
          // component: LoginCustomGizmo,
          component: 'LoginCustomGizmo',
        },
      },
    },
  ],
};

const viewWithFormsIndex: IGizmo = {
  fid: "view-with-forms-index",
  type: "view",
  label: "View with forms index",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    // {
    //   fid: "g0",
    //   type: "text",
    //   label: "Forms Index",
    // },
    {
      fid: "g1",
      type: "index",
      label: "Formatos",
      ops: {
        displayLabel: false,
        index: {
          rowType: "forms",
        },
      },
    },
    // {
    //   fid: 'g2',
    //   type: 'index',
    //   label: 'Formatos de sistema',
    //   ops: {
    //     displayLabel: false,
    //     index: {
    //       rowType: 'system'
    //     }
    //   }
    // }
  ],
};

const viewWithRegistersIndex: IGizmo = {
  fid: "view-with-registers-index",
  type: "view",
  label: "View with registers index",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    // {
    //   fid: "g0",
    //   type: "text",
    //   label: "Registers Index",
    // },
    // {
    //   fid: "g11",
    //   type: "indicator",
    //   label: "Indicador",
    //   ops: {
    //     indicator: {
    //       rootGizmoFid: "63ea691e4732de000aa3206f"
    //     }
    //   }
    // },
    {
      fid: "g1",
      type: "index",
      label: "Registers Index",
      ops: {
        displayLabel: false,
        index: {
          rowType: "form.registers",
          operations: [
            {
              label: "Ver",
              type: "register.show",
            },
            {
              label: "Editar",
              type: "register.edit",
              flux: "{{user.q11000}} == \"soporte\""
            },
          ],
        },
      },
    },
  ],
};

const viewWithSystemDocumentsIndex: IGizmo = {
  fid: "view-with-system-documents-index",
  type: "view",
  label: "View with system documents index",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "g0",
      type: "text",
      label: "System Index",
    },
    {
      fid: "g1",
      type: "index",
      label: "Registers Index",
      ops: {
        index: {
          rowType: "system.documents",
          operations: [
            {
              label: "Ver",
              type: "register.show",
            },
          ],
        },
      },
    },
  ],
};

const viewNavbar: IGizmo = {
  fid: "view-navbar",
  type: "view",
  label: "View Navbar",
  ops: {
    displayLabel: false,
  },
  gizmos: [
    {
      fid: "g100",
      type: "row",
      label: "Row",
      ops: {
        row: {
          columns: {
            xl: 4,
            l: 4,
            m: 3,
            s: 2,
          },
        },
      },
      gizmos: [
        {
          fid: "g4",
          type: "text",
          label: "",
        },
        {
          fid: "g1",
          type: "text",
          // label: "Username",
          label: "usuario: {{user.username}}",
        },
        {
          fid: "g0",
          type: "button",
          label: "Logout",
          ops: {
            button: {
              operation: "auth.logout",
              confirmation: {
                title: "Cerrar sesión",
                description: "¿Estás seguro que quieres cerrar sesión?",
              },
            },
          },
        },
      ]
    },
  ],
}

const defaultCoreProject: IFlowyProject = {
  fid: "default-core-project",
  type: "project",
  label: "Default Core Project",
  views: {
    gizmos: [loginForm, viewWithFormsIndex, viewWithRegistersIndex, viewWithSystemDocumentsIndex],
  },
  navbar: {
    gizmos: [viewNavbar],
  },
  navigation: {
    routes: [
      {
        fid: "r0",
        type: "route",
        label: "Login",
        uid: "login",
        ops: {
          route: {
            path: "/login",
            rootGizmoFid: loginForm.fid,
            index: false,
          },
        },
      },
      {
        fid: "r1",
        type: "route",
        label: "Forms Index",
        uid: "forms_index",
        ops: {
          route: {
            rootGizmoFid: viewWithFormsIndex.fid,
            index: true,
          },
        },
      },
      {
        fid: "r2",
        type: "route",
        label: "Registers Index",
        uid: "registers_index",
        ops: {
          route: {
            rootGizmoFid: viewWithRegistersIndex.fid,
            index: false,
            path: "/r",
          },
        },
      },
      {
        fid: "r3",
        type: "route",
        label: "System Documents Index",
        uid: "system_documents_index",
        ops: {
          route: {
            rootGizmoFid: viewWithSystemDocumentsIndex.fid,
            index: false,
            path: "/sd",
          },
        },
      }
    ],
  },
};

export default defaultCoreProject;
